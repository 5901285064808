.galeryItem{
  height: 250px !important;
  cursor: pointer;
}
.slide .galleryImg{
  height: 400px !important;
}
.carousel .thumbs-wrapper{
  margin: 0px !important;
}
.carousel .thumb img {
  height: 100px !important;
}
.carousel .thumb.selected, .carousel .thumb:hover {
  border: 3px solid #db0206 !important;
}
@media (min-width: 960px){
.carousel .control-dots {
    bottom: 38px !important;
}
}
