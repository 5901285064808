.title { text-align: center; color: #ffc515; }
.para{text-align: justify;}
.homeicons{width: 60%;}
.center{text-align: center;}
.dark { margin-top: 10px; background-image: url(../../src/images/bgimg1.png); background-size: cover; padding: 5%;}
.light { margin-top: 10px; background-image: url(../../src/images/cities/back.png); background-size: cover;}
.servicepic{width: 100%;}
.servicetitle{font-weight: 700; margin-top: 10px;}
body{overflow-x: hidden;}
.citemleft{margin-left: 5%;}

.carousel-control-prev-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    background-color: black;
}

.carousel-control-next-icon {    
    border: 1px solid #fff;
    background-color: black;
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: -1rem;
    margin-left: 15%;
    list-style: none;
}

/* .client{height: 180px;} */
.client{
  height: 200px;
    margin-bottom: 20px;
    box-shadow: 1px 2px 10px 1px #9a9ea1;
}
.city {width: 100%; background-color: white; box-shadow: 10px 10px 10px 10px #eee;}

/* Image hover */
.containerimg {
    position: relative;
    width: 100%;
  }
.image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  
  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 92%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .container:hover .image {
    opacity: 0.3;
  }
  
  .container:hover .middle {
    opacity: 1;
  }
  
  .text {
    background-color: #ffc515;
    color: black;
    font-size: 12px;
    padding: 5px 10px;
  }

  .wicon{width: 100%;
    /* margin-left: 40px;  */
         /* background-color: #ffc515; */
         border: none;
        color: white;    
        /* opacity: 0.6; */
        transition: 0.3s;}
      .wicon:hover {opacity: 1}
     .twhite{color: #fff;}

  
  .dropdown-item:focus, .dropdown-item:hover {
    color: var(--bs-dropdown-link-hover-color);
    background-color: #feb819;
}

.sectiontitle{color: #ffb701;}
.text-white{color: #ffffff;}
.facts{color: #ffc107;
font-size: 35px;
font-weight: 700;}

.location_title{color: #ffc107;
  font-size: 18px;
  font-weight: 700;}

  .location_icons{width: 3%;text-align: right;}

  .formbg{padding: 5%;
    background-color: #ffc107;}

    #pentagon {
      position: relative;
      width: 54px;
      box-sizing: content-box;
      border-width: 50px 18px 0;
      border-style: solid;
      border-color: red transparent;
    }
    #pentagon:before {
      content: "";
      position: absolute;
      height: 0;
      width: 0;
      top: -85px;
      left: -18px;
      border-width: 0 45px 35px;
      border-style: solid;
      border-color: transparent transparent red;
    }

    .team{
      width: 16rem;
      height: 300px;
      padding: 5px;
      margin-bottom: 20px;
      text-align: center;
      box-shadow: 2px 3px 20px 0px #000;
    }

    .team_img{
      margin-left: 18%;
      margin-top: 8%;width: 60%;
      box-shadow: 0px 1px 8px 0px;
    border-radius: 50%;
    }
    .equelDiv{
      width: 9.09090909091%;
      float: left;
      text-align: center;
      font-size: 13px;
    }
  
  /* Image hover */