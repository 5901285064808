.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/* .navbar-expand-sm .navbar-nav .nav-link {
  padding-left: 1.5rem;
} */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}
.menuactive{color:red; background-color:black}
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}


.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}

.submit-form {
  max-width: 300px;
  margin: auto;
}

.edit-form {
  max-width: 300px;
  margin: auto;
}

.logo{width: 30%;}
.icons{width: 8%;text-align: right;}
.layout{padding-top:1%; padding-left: 2%; padding-right: 2%; width: 100%; background-color: #ffb701;}
.navbar-dark .navbar-nav .nav-link {
  color: rgb(255 197 21);
}

@media (min-width: 768px){
.navbar-expand-sm .navbar-nav .nav-link {
    padding-left: 1.5rem;
}

}
  @media only screen and (max-width: 576px) {
  /* .navbar{
    flex-wrap:inherit !important;
  } */
  .equelDiv {
    width: 50% !important;
  }
  .navbar-brand{
    width: 80%;
  }
  .team{
    width: 100%;
  }
}
.navbar-nav {
  /* display: -webkit-inline-box; */
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  /* margin-left: 16%; */
  list-style: none;
}

.dropdown-menu[data-bs-popper] {
  top: 130%;
  background-color: #212529;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #ffb701;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;}

  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 6rem;
    left: 15%;
    color: #fff;
    text-align: center;
}
.sliderh{height: 100%;}
.sheading{background-color: #212529;}
.ssubheading{background-color: #ffc515; color: #212529;}
a {
  color: #000;
  /* color: #ffc515; */
}
.footerbg{background-color: #312f30; color: #fff; margin-top: 20px; padding: 20px;}
.footerlogo{width: 50%;}
.footertitle{color: #ffb701;}
.footerp{font-size: 14px; margin-top: 5px;}

/* animation class and keyframes */
.overflow-hidden {
  overflow: hidden;
 }
 
 .drop-in {
  animation: drop-in 1s ease 200ms backwards;
 }
 
 .drop-in-2 {
  animation: drop-in 1200ms ease 500ms backwards;
 }
 
 @keyframes drop-in {
  from {
   opacity: 0;
   transform: translateY(-100px);
  }
  to {
   opacity: 1;
   transform: translate(0px);
  }
 }
 

 .imgCaption {  
  position: absolute;
  background: #000c;
  width: 100%;
  text-align: center;
  padding: 10px;
  color: #ffb701;
  font-weight: bold;
  text-transform: capitalize;
 }
 .modal-title{
  text-transform: capitalize;
 }